/*
 * @Author: xiongyahui
 * @Date: 2023-06-06 10:32:39
 * @LastEditors: xiongyahui
 * @LastEditTime: 2023-06-06 16:12:19
 * @Description:
 */
import React, { useState } from "react"
import AdvancedSearchBtn from "@/components/AdvancedSearchBtn/index"
import { Select } from "antd"
import "./index.scss"
import { useEffect } from "react"
import { SearchType } from "@/utils/common"

export interface ISearchBoxValue {
  type?: SearchType
  value?: string
}

interface ISearchBoxProps {
  className?: string
  onSearch: (obj: ISearchBoxValue) => void
  initValue?: ISearchBoxValue
}

const SearchBox: React.FC<ISearchBoxProps> = (props) => {
  const [inputVal, setInputVal] = useState(props.initValue?.value || "")
  const [selectVal, setSelectVal] = useState<any>(props.initValue?.type || "author")
  const selectList = [
    { name: "Author", value: "author" },
    { name: "Paper", value: "paper" },
    { name: "Chemical", value: "chemical" },
    { name: "Organization", value: "org" },
    { name: "Gene", value: "gene" },
    { name: "Disease", value: "disease" },
    { name: "Mutation", value: "mutation" }
  ]

  useEffect(() => {
    const { type, value } = props.initValue || {}
    if (value) {
      setInputVal(value)
    }
    if (type) {
      setSelectVal(type)
    }
  }, [props.initValue])

  function onSearch() {
    if (!inputVal?.trim()) return
    props.onSearch({ type: selectVal, value: inputVal.trim() })
    setInputVal("")
  }

  function onAdvanceSubmit(values: any) {
    props.onSearch({ type: "advanced", value: values })
  }

  function onInputChange(event: any) {
    setInputVal(event.target.value)
  }

  function onSelectChange(value: string) {
    setSelectVal(value)
  }
  return (
    <div className={"search-container " + props.className}>
      <div className="search-wrapper">
        <Select className="search-select" value={selectVal} onChange={onSelectChange}>
          {selectList.map((item, index) => (
            <Select.Option key={index} value={item.value}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        <input
          type="text"
          className="search-input"
          placeholder="search"
          value={inputVal}
          onChange={onInputChange}
        />
        <button onClick={onSearch}>
          <img width={20} src="/assets/images/search.svg" />
        </button>
      </div>
      <AdvancedSearchBtn onSubmit={onAdvanceSubmit} />
    </div>
  )
}

export default SearchBox
