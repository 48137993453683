import React, { useEffect, useState } from "react"
import "./index.scss"
import { SearchType } from "@/utils/common"

export const typeMapping: any = {
  author: ["Relevance", "h-index", "g-index", "paper_num", "citation"],
  org: ["author_num", "paper_num", "cited_num", "h-index", "g-index"],
  journal: ["impact_factor", "paper_num", "cited_num"],
  other: ["Citation", "Year"]
}

interface SearchSortProps {
  type?: SearchType
  options?: string[]
  value: string
  onChange: (val: string) => void
}

const SearchSort: React.FC<SearchSortProps> = ({ type, options, value, onChange }) => {
  const [checkVal, setCheckVal] = useState<string>("")
  const list = options || typeMapping[type || "other"] || typeMapping.other

  useEffect(() => {
    setCheckVal(value)
  }, [value])

  function onClick(val: string) {
    if (val === checkVal) return
    onChange(val)
  }
  return (
    <div className="search-sort-container">
      <div className="search-sort-title">Sorting Options：</div>
      <ul>
        {list.map((item: any, index: number) => (
          <li
            key={index}
            className={checkVal === item ? "checked" : ""}
            onClick={() => onClick(item)}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SearchSort
