import React from "react"
import "./index.scss"
import { Affix } from "antd"

interface IMeshClassProps {
  checked: string
  meshList?: { name: string; mesh: string }[]
  clickCallback: (mesh: string) => void
}

const meshMapping = [
  { name: "Anatomy", mesh: "A" },
  { name: "Organisms", mesh: "B" },
  { name: "Diseases", mesh: "C" },
  { name: "Chemicals & Drugs", mesh: "D" },
  { name: "Techniques & equipment", mesh: "E" },
  { name: "Psychiatry & Psychology", mesh: "F" },
  { name: "Phenomena & Processes", mesh: "G" },
  { name: "Disciplines & Occupations", mesh: "H" },
  { name: "Anthropology & Sociology", mesh: "I" },
  { name: "Industry & Agriculture", mesh: "J" },
  { name: "Humanities", mesh: "K" },
  { name: "Information Science", mesh: "L" },
  { name: "Named Groups", mesh: "M" },
  { name: "Health Care", mesh: "N" },
  { name: "Publication Characteristics", mesh: "V" },
  { name: "Geographicals", mesh: "Z" }
]

const MeshClass: React.FC<IMeshClassProps> = ({
  meshList = meshMapping,
  clickCallback,
  checked = "C"
}) => {
  return (
    <Affix offsetTop={90}>
      <div className="mesh-class-wrapper">
        <div className="mesh-class-title">MESH CLASS</div>
        <ul>
          {meshList.map((item, index) => (
            <li
              onClick={() => clickCallback(item.mesh)}
              key={index}
              className={item.mesh === checked ? "mesh-checked" : ""}
            >{`${item.name}[${item.mesh}]`}</li>
          ))}
          <li></li>
        </ul>
      </div>
    </Affix>
  )
}

export default MeshClass
