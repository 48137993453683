/*
 * @Author: xiongyahui
 * @Date: 2021-11-30 09:48:07
 * @LastEditors: xiongyahui
 * @LastEditTime: 2022-07-07 15:13:48
 * @Description:
 */
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import DocumentTitle from "react-document-title"
import { Button, Result } from "antd"
import { SYSTEM_NAME } from "../../settings"
import { baiduAnalysis } from "@/utils/utils"

const NoFoundPage: React.FC = () => {
  const history = useHistory()
  useEffect(() => {
    baiduAnalysis()
  }, [])
  return (
    <DocumentTitle title={`404-${SYSTEM_NAME}`}>
      <Result
        status="404"
        title="404"
        subTitle="您访问的页面不存在！"
        extra={
          <Button type="primary" onClick={() => history.push("/")}>
            回到首页
          </Button>
        }
      />
    </DocumentTitle>
  )
}

export default NoFoundPage
