import React, { useEffect, useMemo, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Affix, Spin } from "antd"
import Header from "@/components/Header"
import LineChart from "./components/LineChart"
import AuthorContent from "./components/AuthorContent"
import { getAuthorDetail, getPapersByAuthor } from "@/services"
import { baiduAnalysis } from "@/utils/utils"

const features = [
  {
    title: "h-index",
    value: "h-index"
  },
  {
    title: "g-index",
    value: "g-index"
  },
  {
    title: "paper_num",
    value: "paper_num"
  },
  {
    title: "cited_num",
    value: "citation"
  }
]

const AuthorDetail: React.FC = () => {
  const history = useHistory()
  const { id: authorId } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [coauthors, setCoauthors] = useState<any[]>([])
  const [coauthorIsDesc, setCoauthorIsDesc] = useState<boolean>(true) //合作者是否是降序排列
  const [authorData, setAuthorData] = useState<Record<string, any>>({
    papers_pubyear_array: []
  })
  const [paperData, setPaperData] = useState({
    data: [],
    pageno: 1,
    totol: 0
  })

  //统计数据，包含了图表以及年份对应的论文数量
  const staticData = useMemo(() => {
    const data: number[] = [],
      xAxis: number[] = [],
      years: any = {}
    ;(authorData.papers_pubyear_array as [])?.map((item: any) => {
      data.push(item.paper_num)
      xAxis.push(item.pubyear)
      years[item.pubyear] = item.paper_num
    })
    years.ALL = data.reduce(function (prev, cur) {
      return prev + cur
    }, 0)
    return {
      chart: { data, xAxis },
      years
    }
  }, [authorData.papers_pubyear_array])

  useEffect(() => {
    baiduAnalysis()
  }, [])

  useEffect(() => {
    if (!authorId) return
    setLoading(true)
    Promise.all([
      getAuthorDetail(authorId),
      getPapersByAuthor(authorId, "all", { pageno: 1, pagesize: 20 })
    ])
      .then((res) => {
        res[0].papers_pubyear_array.sort((a: any, b: any) => a.pubyear - b.pubyear)
        res[0].co_author.sort((a: any, b: any) => b.num_cowork - a.num_cowork)
        setCoauthors(res[0].co_author)
        delete res[0].co_author
        setAuthorData(res[0])
        setPaperData(res[1])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [authorId])

  //搜索方法
  function onSearch(data: any) {
    let obj = data.value
    if (typeof data.value === "string") {
      obj = { [data.type]: data.value }
    }
    window.sessionStorage.setItem("gtx-miner-paperOption", JSON.stringify(obj))
    history.push("/search")
  }

  //合作者排序变化
  function onChangeSort() {
    coauthors.sort((a: any, b: any) => {
      return coauthorIsDesc ? a.num_cowork - b.num_cowork : b.num_cowork - a.num_cowork
    })
    setCoauthors(coauthors)
    setCoauthorIsDesc(!coauthorIsDesc)
  }

  function onCoAuthorClick(id: any) {
    history.push(`/author-detail/${id}`)
  }

  return (
    <div>
      <Affix offsetTop={0}>
        <Header onSearch={onSearch} />
      </Affix>
      <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
        <div className="content-container" style={{ height: "calc(100vh - 90px)" }}>
          {/* 基本信息区域 start */}
          <div className="author-info-container">
            <div className="author-info-avatar">
              <img src={authorData.pic_url || "/assets/images/avatar.png"} width={160} alt="" />
            </div>
            <div className="author-info-base">
              <div className="info-base-name">{authorData.author_name}</div>
              <ul>
                {features.map((item, index) => (
                  <li key={index}>
                    {item.title} : <span>{authorData[item.value]}</span>
                  </li>
                ))}
              </ul>
              <div className="info-base-org">
                <img src="/assets/images/org.svg" alt="" />
                {authorData.organization}
              </div>
              <div className="info-base-chart">
                <LineChart {...staticData.chart} height="260px" />
              </div>
            </div>
            <div className="author-info-co">
              <div className="author-co_head">
                <img src="/assets/images/coauthor.svg" alt="" />
                CoAuthors
              </div>
              <ul>
                <li>
                  <span>Name</span>
                  <span
                    onClick={() => onChangeSort()}
                    style={{ float: "right", color: "#2A61E9", cursor: "pointer" }}
                  >
                    Number of cooperation {coauthorIsDesc ? "↓" : "↑"}
                  </span>
                </li>
              </ul>
              <ul style={{ height: 280, overflow: "auto" }}>
                {coauthors.map((item: any, index: number) => (
                  <li key={index}>
                    <span className="author-co_number">{item.num_cowork}</span>
                    <div
                      className="author-co_name"
                      onClick={() => onCoAuthorClick(item.author_uid)}
                    >
                      {item.author_name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* 基本信息区域 end */}
          <AuthorContent
            authorId={authorId}
            years={staticData.years}
            initPaperData={paperData}
            setPageLoading={setLoading}
          />
        </div>
      </Spin>
    </div>
  )
}

export default AuthorDetail
