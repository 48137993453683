/*
 * @Author: xiongyahui
 * @Date: 2021-11-30 09:48:07
 * @LastEditors: xiongyahui
 * @LastEditTime: 2022-07-07 14:56:34
 * @Description:
 */
import React from "react"
import { HashRouter as Router, Route, Switch } from "react-router-dom"

import Home from "./pages/home/Home"
import Search from "./pages/search/Search"
import NotFound from "./pages/other/NotFound"
import Rank from "./pages/ranking"
import AuthorDetail from "./pages/detail/Author"
import OrgDetail from "./pages/detail/Org"

const Page: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/search" component={Search} />
        <Route path="/rank" component={Rank} />
        <Route path="/author-detail/:id" component={AuthorDetail} />
        <Route path="/org-detail/:id" component={OrgDetail} />
        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}
export default Page
