import React, { useState, useEffect, useMemo } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Affix, Spin } from "antd"
import Header from "@/components/Header"
import { getOrgDetail, getPapersByOrg } from "@/services"
import LineChart from "./components/LineChart"
import OrgContent from "./components/OrgContent"
import { baiduAnalysis } from "@/utils/utils"

const features = ["h-index", "g-index", "paper_num", "cited_num", "author_num"]

const OrgDetail: React.FC = () => {
  const history = useHistory()
  const { id: orgId } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [orgData, setOrgData] = useState<Record<string, any>>({
    paper_pubyear_array: []
  })

  const [paperData, setPaperData] = useState({
    data: [],
    pageno: 1,
    totol: 0
  })

  const staticData = useMemo(() => {
    const data: number[] = [],
      xAxis: number[] = [],
      years: any = {}
    ;(orgData.paper_pubyear_arr as [])?.map((item: any) => {
      data.push(item.paper_num)
      xAxis.push(item.pubyear)
      years[item.pubyear] = item.paper_num
    })
    years.ALL = data.reduce(function (prev, cur) {
      return prev + cur
    }, 0)
    return {
      chart: { data, xAxis },
      years
    }
  }, [orgData.paper_pubyear_arr])

  useEffect(() => {
    baiduAnalysis()
  }, [])

  useEffect(() => {
    if (!orgId) return
    setLoading(true)
    Promise.all([getOrgDetail(orgId), getPapersByOrg(orgId, "all", { pageno: 1, pagesize: 20 })])
      .then((res) => {
        res[0].paper_pubyear_arr.sort((a: any, b: any) => a.pubyear - b.pubyear)
        setOrgData(res[0])
        setPaperData(res[1])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [orgId])

  //搜索方法
  function onSearch(data: any) {
    let obj = data.value
    if (typeof data.value === "string") {
      obj = { [data.type]: data.value }
    }
    window.sessionStorage.setItem("gtx-miner-paperOption", JSON.stringify(obj))
    history.push("/search")
  }
  return (
    <div>
      <Affix offsetTop={0}>
        <Header onSearch={onSearch} />
      </Affix>
      <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
        <div className="content-container" style={{ height: "calc(100vh - 90px)" }}>
          {/* 基本信息区域 */}
          <div className="org-info-container">
            <div className="org-info-avatar">
              <img src="/assets/images/default_org.png" width={160} height={220} alt="" />
            </div>
            <div className="org-info-base">
              <div className="info-base-name">{orgData.organization_name}</div>
              <ul>
                {features.map((item, index) => (
                  <li key={index}>
                    {item} : <span>{orgData[item]}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="org-info-chart">
              <LineChart {...staticData.chart} height="240px" />
            </div>
          </div>
          <OrgContent
            orgId={orgId}
            years={staticData.years}
            initPaperData={paperData}
            setPageLoading={setLoading}
          />
        </div>
      </Spin>
    </div>
  )
}

export default OrgDetail
