import React, { useState } from "react"
import "./index.scss"

const AdvancedSearchBtn: React.FC<any> = ({ onSubmit }) => {
  const advancedList = [
    { label: "Author contains", placeholder: "words in author name", value: "author" },
    { label: "Paper contains", placeholder: "words in paper title", value: "paper" },
    {
      label: "Organization contains",
      placeholder: "words in organization name",
      value: "organization"
    },
    { label: "Gene contains", placeholder: "words in gene name or gene id", value: "gene" },
    {
      label: "Disease contains",
      placeholder: "words in disease name,MESH id or OMIM id",
      value: "disease"
    },
    {
      label: "Mutation contains",
      placeholder: "words in mutation name or mutation id",
      value: "mutation"
    }
  ]

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState<{ [key: string]: string }>({
    author: "",
    paper: "",
    organization: "",
    gene: "",
    disease: "",
    mutation: ""
  })

  function handleOpen() {
    if (!open) {
      setOpen(true)
    }
  }

  function handleClose() {
    if (open) {
      setOpen(false)
      setValues({
        author: "",
        paper: "",
        organization: "",
        gene: "",
        disease: "",
        mutation: ""
      })
    }
  }

  function onInputChange(e: any, type: string) {
    setValues({
      ...values,
      [type]: e.target.value
    })
  }

  function onClick() {
    const obj = Object.values(values).find((item: string) => item.trim())
    if (obj) {
      onSubmit(values)
    }
    handleClose()
  }

  return (
    <>
      <button className="btn-advanced-search" onClick={handleOpen}>
        Advanced Search
      </button>
      <div className={`advanced-search-container ${open ? "open" : ""}`}>
        <div className="advanced-search-title">
          <img src="/assets/images/search.svg" alt="" />
          Advanced Search
          <img
            className="advanced-search-close"
            src="/assets/images/close.svg"
            onClick={handleClose}
          />
        </div>
        <ul className="advanced-search-items">
          {advancedList.map((item, index) => (
            <li key={index}>
              <div className="advanced-search-label">{item.label}</div>
              <input
                value={values[item.value]}
                className="advanced-search-input"
                type="text"
                placeholder={item.placeholder}
                onChange={(e) => onInputChange(e, item.value)}
              />
              <div className="advanced-search-suffix">AND</div>
            </li>
          ))}
          <li>
            <button onClick={onClick}>Advanced Search</button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default AdvancedSearchBtn
