import React, { useState, useRef } from "react"
import { Modal, Button } from "antd"
import "./index.scss"
import { batchSearch } from "@/services"
import { downloadFile } from "@/utils/utils"

const BatchSearch: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const inputRef = useRef<any>(null)
  const [fileName, setFileName] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  function onFileChange(e: any) {
    const files = e.target.files[0]
    if (files) {
      setFileName(files.name)
    }
  }

  function handleOk() {
    const [file] = inputRef.current?.files
    if (!file) return
    setLoading(true)
    batchSearch(file)
      .then((res) => {
        const name = res.headers["content-disposition"].split("=")[1]
        downloadFile(res.data, name || "result_example.txt")
      })
      .finally(() => {
        inputRef.current.value = ""
        setFileName("")
        setVisible(false)
        setLoading(false)
      })
  }

  function handleCancel() {
    setVisible(false)
  }

  function handleClick() {
    setVisible(true)
  }

  return (
    <div className="batch-search-wrapper">
      <span onClick={handleClick}>Batch Search</span>
      <Modal
        className="batch-search-modal"
        centered
        visible={visible}
        title={[
          <img key={3} src="/assets/images/citation.svg" />,
          <span key={4}>Upload a txt file</span>
        ]}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button className="batch-modal-ok" key={1} loading={loading} onClick={handleOk}>
            Ok
          </Button>,
          <Button className="batch-modal-cancel" key={2} onClick={handleCancel}>
            Cancel
          </Button>
        ]}
      >
        <div className="batch-search-form">
          <div className="batch-search-row">
            <div className="batch-search-title">Txt file:</div>
            <div className="batch-search-content">
              <div>
                {fileName ? <span>{fileName}</span> : "Select a txt file"}
                <input type="file" ref={inputRef} onChange={onFileChange} />
              </div>
            </div>
          </div>
          <div className="batch-search-row">
            <div className="batch-search-title">Read Me:</div>
            <div className="batch-search-content">
              <p>Format of the txt file for batch query.</p>
              <p>First line is the allocated authentication code;</p>
              <p>Items for searching is from the second line;</p>
              <p>Search item line format:</p>
              <p>No.+&lt;TAB&gt;+gene+&lt;TAB&gt;+mutation+&lt;TAB&gt;+disease</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BatchSearch
