import React, { useState, useEffect } from "react"
import { getAuthorRanking } from "@/services"
import { Spin, Pagination } from "antd"
import MeshClass from "@/components/MeshClass"
import { AuthorItem } from "@/components/ResultItem"
import SearchSort from "@/components/SearchSort"
import { baiduAnalysis } from "@/utils/utils"

const AuthorRank: React.FC = () => {
  const searchSortOptions = ["h-index", "g-index", "paper_num", "citation"]

  const [pageParams, setPageParams] = useState<{
    page_no: number
    page_size: number
    sorting_options: string
  }>({
    page_no: 1,
    page_size: 20,
    sorting_options: searchSortOptions[0]
  })
  const [mesh, setMesh] = useState<string>("C")
  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    requestData("C", pageParams)
    baiduAnalysis()
  }, [])

  function onMeshClick(meshVal: string) {
    requestData(meshVal, Object.assign({}, pageParams, { page_no: 1 }))
  }

  function requestData(meshVal: string, data: any) {
    setLoading(true)
    getAuthorRanking(meshVal, data)
      .then((res) => {
        setPageParams(data)
        setMesh(meshVal)
        setList(res.data)
        setTotal(res.total)
        window.scrollTo({
          top: 0
        })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  //分页组件页码切换方法
  function onPageChange(page: number) {
    const data = {
      ...pageParams,
      page_no: page
    }
    requestData(mesh, data)
  }

  function onSortChange(val: string) {
    const data = {
      ...pageParams,
      page_no: 1,
      sorting_options: val
    }
    requestData(mesh, data)
  }
  return (
    <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
      <div className="content-container rank-content-container">
        <MeshClass checked={mesh} clickCallback={onMeshClick} />
        <div className="rank-content-wrapper">
          <div className="rank-author-title">Top1000 Authors:</div>
          <p className="rank-author-desc">
            GTX.Miner uses 16 MESH classes as research domain. The author ranking page of GTX.Miner
            lists the first 100 researchers in various domain. You can choose sorting options you
            are interested in to list the first 100 researchers. The default author ranking page
            lists the first 100 researchers in “Disease” domain. If you have any question, please
            contact: miner@gtxlab.com.
          </p>
          <SearchSort
            value={pageParams.sorting_options}
            onChange={onSortChange}
            options={searchSortOptions}
          />
          <div>
            {list.map((item, index) => (
              <AuthorItem key={index} data={item} rank={item.author_code} />
            ))}
          </div>
          <div className="search-page-wrapper">
            <Pagination
              current={pageParams.page_no}
              total={total}
              pageSize={20}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default AuthorRank
