/*
 * @Author: xiongyahui
 * @Date: 2023-06-06 10:32:39
 * @LastEditors: xiongyahui
 * @LastEditTime: 2023-06-06 17:07:20
 * @Description:
 */
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Checkbox } from "antd"

import "./index.scss"
import { SearchType } from "@/utils/common"

export interface ResultItemProps {
  type: SearchType
  data: any
  rank?: number
  showCheck?: boolean //是否显示复选框
  checkList?: any[] //选中的list集合
  onCheckChange?: (result: any[]) => void
}

//author结果条目
export const AuthorItem: React.FC<{ data: any; rank?: number }> = React.memo(({ data, rank }) => {
  const features = ["h-index", "g-index", "paper_num", "citation"]
  return (
    <div className="result-item">
      <div className={"result-author-item " + (rank ? "result-author-rank-item" : "")}>
        {rank && <span>{rank}</span>}
        <img src={data.pic_url || "/assets/images/avatar.png"} />
        <div>
          <div className="author-item-title">
            <Link to={`/author-detail/${data.author_uid}`}>{data.author_name}</Link>
          </div>

          <ul>
            {features.map((item, index) => (
              <li key={index}>
                {item}: <span>{data[item]}</span>
              </li>
            ))}
          </ul>
          {data.organization && (
            <div className="author-item-org">
              <img src="/assets/images/org.svg" alt="" />
              {data.organization}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export const OrgItem: React.FC<{ data: any }> = React.memo(({ data }) => {
  const features = ["author_num", "paper_num", "cited_num", "h-index", "g-index"]
  return (
    <div className="result-item">
      <div className="result-org-item">
        <div className="org-item-title">{data.organization_name}</div>
        <ul>
          {features.map((item, index) => (
            <li key={index}>
              {item}: <span>{data[item]}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
})

export const ChemicalItem: React.FC<{ data: any }> = React.memo(({ data }) => {
  return (
    <div className="result-item">
      <div className="result-chemical-item">
        <div className="chemical-item-title">{data.name}</div>
        <div>
          pubchem compound ID:{" "}
          <a href={`https://pubchem.ncbi.nlm.nih.gov/compound/${data.id}`} target="_blank">
            {data.id}
          </a>
        </div>
        <ul>
          {data.related_entity_list.map((item: any, index: number) => (
            <li key={index} style={{ marginTop: 4 }}>
              <span>
                {item.entity_type}: {item.entity_name};
              </span>
              <span style={{ marginLeft: 10, marginRight: 4 }}>EVIDENCE(PMID):</span>
              {item.evidence.map((ev: string, idx: number) => (
                <a href={`https://pubmed.ncbi.nlm.nih.gov/${ev}`} target="_blank" key={idx}>
                  {ev}
                </a>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
})

//其他结果条目
export const OtherItem: React.FC<{
  data: any
  rank?: number
  showCheck?: boolean //是否显示复选框
  checkList?: any[] //选中的list集合
  onCheckChange?: (result: any[]) => void
}> = React.memo(({ data, rank, showCheck, checkList, onCheckChange }) => {
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    setOpen(false)
  }, [data])

  const checked = useMemo(() => {
    return checkList?.includes(data.paper_pmid)
  }, [data, checkList])

  function onChange() {
    if (!onCheckChange) return
    const checkArray: any[] = checkList || []
    let result
    if (checked) {
      result = checkArray.filter((item) => item !== data.paper_pmid)
    } else {
      result = [...checkArray, data.paper_pmid]
    }
    onCheckChange(result)
  }

  return (
    <div className="result-item" style={{ display: "flex" }}>
      {showCheck && (
        <div className="result-item-checkbox">
          <Checkbox checked={checked} onChange={onChange} />
        </div>
      )}
      <div className="result-other-item">
        <a
          className="other-item-title"
          href={`https://www.ncbi.nlm.nih.gov/pubmed/${data.paper_pmid}`}
          target="_blank"
        >
          {rank && <span>{rank}</span>}
          {data.paper_title}
        </a>
        <div className="other-item-row">
          <img src="/assets/images/authors.svg" alt="" />
          <ul>
            {(open ? data.author_obj_array : data.author_obj_array?.slice(0, 4))?.map(
              (item: any, index: number) => (
                <li key={index}>
                  <Link to={`/author-detail/${item.author_uid}`}>{item.author_name}</Link>
                </li>
              )
            )}
            {data.author_obj_array?.length > 4 && (
              <li className={"item-arrow-wrapper " + (open ? "open" : "")}>
                <img src="/assets/images/arrow_down.png" onClick={() => setOpen(!open)} />
              </li>
            )}
          </ul>
        </div>

        <div className="other-item-row">
          <img src="/assets/images/org_year.svg" alt="" />
          <span>{data.magazine_obj && data.magazine_obj[0]?.magazine_name}</span>
          {data.paper_pubyear && <span>({data.paper_pubyear})</span>}
        </div>

        <div className="other-item-row ">
          <img src="/assets/images/citation.svg" alt="" />
          <span>Citation: {data.citation}</span>
        </div>
      </div>
    </div>
  )
})

const ResultItem: React.FC<ResultItemProps> = React.memo(({ type, data, ...props }) => {
  let component = null
  switch (type) {
    case "author":
      component = <AuthorItem data={data} {...props} />
      break
    case "org":
      component = <OrgItem data={data} />
      break
    case "chemical":
      component = <ChemicalItem data={data} />
      break
    default:
      component = <OtherItem data={data} {...props} />
  }
  return component
})

export default ResultItem
