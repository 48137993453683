//系统配置项

//document.title后缀值
export const SYSTEM_NAME = "系统名称"

/** 登录页面配置 **/
//标题文案
export const LOGIN_TITLE = "人和未来"
//底部文案
export const LOGIN_FOOTER_TITLE = "©人和未来生物科技（长沙）有限公司 湘ICP备15003005号-1"

//侧边菜单栏标题
export const SIDE_TITLE = "系统名称"
