import axios from "../utils/request"
import { SearchType } from "@/utils/common"

//查询列表，条件为author的接口

export interface ISearchListParams {
  type: SearchType
  value: string | Record<string, string>
  data: any
}

export interface IRankingPrarams {
  page_no: number
  page_size: number
  sorting_options: string
}

export function getSearchList(data: ISearchListParams): Promise<any> {
  if (data.type !== "advanced") {
    return axios.get(`/search_${data.type}/${data.value}`, { params: data.data })
  }
  return axios.get(`/advanced_search`, {
    params: { ...data.data, ...(data.value as Record<string, string>) }
  })
}

export function getRanking(type: SearchType, data: IRankingPrarams): Promise<any> {
  return axios.get(`/${type}_ranking`, {
    params: data
  })
}

export function getPaperRanking(mesh: string, data: unknown): Promise<any> {
  return axios.get(`/paper_ranking/${mesh}`, {
    params: data
  })
}

export function getAuthorRanking(mesh: string, data: unknown): Promise<any> {
  return axios.get(`/author_ranking/${mesh}`, {
    params: data
  })
}

export function getAuthorDetail(id: string): Promise<any> {
  return axios.get(`/detail_author/${id}`)
}

export function getPapersByAuthor(
  authorId: string,
  year: string | number,
  data: unknown
): Promise<any> {
  return axios.get(`/author_papers/${authorId}/year/${year}`, {
    params: data
  })
}

export function submitFeedback(
  authorId: string,
  year: string | number,
  data: unknown
): Promise<any> {
  return axios.post(`/feedback/${authorId}/year/${year}`, data)
}

export function getOrgDetail(id: string): Promise<any> {
  return axios.get(`/detail_organization/${id}`)
}

export function getPapersByOrg(orgId: string, year: string | number, data: unknown): Promise<any> {
  return axios.get(`/org_papers/${orgId}/year/${year}`, {
    params: data
  })
}

export function getAuthorByOrg(orgId: string, data: unknown): Promise<any> {
  return axios.get(`/org_authors/${orgId}`, {
    params: data
  })
}

export function downloadPapersPost(data: any[]): Promise<any> {
  return axios.post("/papers_post", { data }, { responseType: "arraybuffer" })
}

export function batchSearch(blob: File): Promise<any> {
  const fileUpload = new FormData()
  fileUpload.append("uploadfile", blob)
  return axios.post("/batch_search_upload", fileUpload, { responseType: "arraybuffer" })
}
