/*
 * @Author: xiongyahui
 * @Date: 2021-11-30 09:48:07
 * @LastEditors: xiongyahui
 * @LastEditTime: 2022-07-07 15:19:54
 * @Description:
 */
//数组去重
declare let window: Window & { _hmt: any; URL: any }

export function unique<T>(arr: T[]): T[] {
  return Array.from(new Set(arr))
}

export function downloadFile(data: ArrayBuffer, file_name: string): void {
  const blob = new Blob([data], { type: "text/plain" })
  const fileName = file_name
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   window.navigator?.msSaveBlob(blob, fileName)
  // } else {
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  // 释放内存
  window.URL.revokeObjectURL(link.href)
  // }
}
export function baiduAnalysis(): void {
  if (window._hmt) {
    console.log(location.href)
    window._hmt.push(["_trackPageview", "/" + location.hash])
  }
}
