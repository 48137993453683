export interface IUserState {
  readonly token: string
}

export enum ActionTypes {
  SET_TOKEN = "setToken",
  REMOVE_TOKEN = "removeToken"
}

export interface ISetTokenAction {
  type: ActionTypes.SET_TOKEN
  value: string
}

export interface IRemoveTokenAction {
  type: ActionTypes.REMOVE_TOKEN
}

export type ActionList = ISetTokenAction | IRemoveTokenAction
