import { ActionTypes, ActionList, IUserState } from "./types"

const initialState: IUserState = {
  token: window.localStorage.getItem("token") || ""
}

export default function User(state = initialState, action: ActionList): IUserState {
  switch (action.type) {
    case ActionTypes.SET_TOKEN:
      return { ...state, token: action.value }
    case ActionTypes.REMOVE_TOKEN:
      return { ...state, token: "" }
    default:
      return state
  }
}
