import React, { useEffect, useState } from "react"
import { Popover, Form, Input, Button, message } from "antd"
import { submitFeedback } from "@/services"
import PapersTabContent from "./PapersTabContent"

interface IAuthorContentProps {
  authorId: string
  initPaperData: any
  years: Record<string | number, number>
  setPageLoading: (loading: boolean) => void
}

const AuthorContent: React.FC<IAuthorContentProps> = ({
  authorId,
  initPaperData,
  years,
  setPageLoading
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [year, setYear] = useState<string | number>("all")

  const SumbitComponent: React.FC = () => {
    const [form] = Form.useForm()

    function onFinish(values: any) {
      const obj = Object.values(values).find((item: any) => item)
      if (obj) {
        setLoading(true)
        submitFeedback(authorId, year, values)
          .then(() => {
            setVisible(false)
            message.success("Submitted successfully!")
            form.resetFields()
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
    return (
      <div>
        <Form form={form} autoComplete="off" onFinish={onFinish} size="large">
          <Form.Item
            name="author"
            // rules={[{ required: true, message: "Please input author name" }]}
          >
            <Input placeholder="Please input author name" width={350} />
          </Form.Item>
          <Form.Item name="org">
            <Input placeholder="Please input organization name" width={350} />
          </Form.Item>
          <Form.Item name="papers">
            <Input placeholder="Please input paper information" width={350} />
          </Form.Item>
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: 350 }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  useEffect(() => {
    //作者发生变化，重置year
    setYear("all")
  }, [authorId])

  function onVisibleChange(vis: boolean) {
    setVisible(vis)
  }

  function onFinishChangeYear(newYear: string | number) {
    setYear(newYear)
  }

  return (
    <div className="detail-content-container">
      <div className="detail-menu-container">
        <div className="detail-menu-item active">Papers</div>
        <Popover
          content={<SumbitComponent />}
          placement="right"
          trigger="click"
          visible={visible}
          onVisibleChange={onVisibleChange}
          overlayClassName="detail-info-popover"
        >
          <div className="detail-info-submit">I want to submit information</div>
        </Popover>
      </div>
      <div className="detail-content-wrapper">
        <PapersTabContent
          initData={initPaperData}
          authorId={authorId}
          years={years}
          onFinishChangeYear={onFinishChangeYear}
          setPageLoading={setPageLoading}
        />
      </div>
    </div>
  )
}

export default AuthorContent
