import React, { useState } from "react"
import AuthorTabContent from "./AuthorTabContent"
import PapersTabContent from "./PapersTabContent"

interface IOrgContentProps {
  orgId: string
  initPaperData: any
  years: Record<string | number, number>
  setPageLoading: (loading: boolean) => void
}
const OrgContent: React.FC<IOrgContentProps> = ({
  orgId,
  initPaperData,
  years,
  setPageLoading
}) => {
  const [activeMenu, setActiveMenu] = useState<string>("Papers")
  return (
    <div className="detail-content-container" style={{ height: "calc(100% - 290px)" }}>
      <div className="detail-menu-container">
        {["Papers", "Author"].map((item, index) => (
          <div
            onClick={() => item !== activeMenu && setActiveMenu(item)}
            className={"detail-menu-item " + (activeMenu === item ? "active" : "")}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
      <div
        className={
          "detail-content-wrapper " + (activeMenu === "Author" ? "detail-content-author" : "")
        }
      >
        <PapersTabContent
          initData={initPaperData}
          orgId={orgId}
          years={years}
          setPageLoading={setPageLoading}
        />
        <AuthorTabContent orgId={orgId} setPageLoading={setPageLoading} />
      </div>
    </div>
  )
}

export default OrgContent
