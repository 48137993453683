import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Affix, Spin, Pagination } from "antd"
import { getRanking, IRankingPrarams } from "@/services"
import SearchSort from "@/components/SearchSort"
import { SearchType } from "@/utils/common"
import { baiduAnalysis } from "@/utils/utils"

const features: Record<any, string[]> = {
  org: ["author_num", "paper_num", "cited_num", "h-index", "g-index"],
  journal: ["impact_factor", "paper_num", "cited_num"]
}

const CommonRank: React.FC<{ type: SearchType }> = ({ type = "org" }) => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [pageTotal, setPageTotal] = useState<number>(1)
  const [pageParams, setPageParams] = useState<IRankingPrarams>({
    page_no: 1,
    page_size: 20,
    sorting_options: features[type][0]
  })

  useEffect(() => {
    requestData(pageParams)
    baiduAnalysis()
  }, [])

  function onClickTitle(orgId: string) {
    if (type === "org") {
      history.push(`/org-detail/${orgId}`)
    }
  }

  function onSortChange(val: string) {
    const data: IRankingPrarams = {
      ...pageParams,
      page_no: 1,
      sorting_options: val
    }
    requestData(data)
  }

  //分页组件页码切换方法
  function onPageChange(page: number) {
    const data: IRankingPrarams = {
      ...pageParams,
      page_no: page
    }

    requestData(data)
  }

  //请求数据
  function requestData(data: IRankingPrarams) {
    setLoading(true)
    getRanking(type, data)
      .then((res) => {
        setList(res.data || res.journal_info_array)
        setPageTotal(res.total)
        setPageParams(data)
        window.scrollTo({
          top: 0
        })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
      <div className="content-container" style={{ minHeight: 300 }}>
        <Affix offsetTop={90}>
          <div style={{ backgroundColor: "#fff" }}>
            <div className="search-content-wrapper" style={{ fontSize: 20 }}>
              Top1000 {type === "org" ? "organizations" : "journals"}:
            </div>
            <SearchSort value={pageParams.sorting_options} type={type} onChange={onSortChange} />
          </div>
        </Affix>
        {list.length !== 0 && (
          <>
            <div>
              {list.map((item, index) => (
                <div key={index} className="rank-content-box">
                  <div
                    className="rank-title-wrapper"
                    onClick={() => onClickTitle(item.organization_uid)}
                  >
                    <span className="rank-rank">{item.organization_code || item.journal_code}</span>
                    {item.organization_name || item.name}
                  </div>
                  <ul>
                    {features[type]?.map((feature, idx) => (
                      <li key={idx}>
                        {feature}:<span>{item[feature]}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="search-page-wrapper">
              <Pagination
                current={pageParams.page_no}
                total={pageTotal}
                pageSize={20}
                onChange={onPageChange}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </div>
    </Spin>
  )
}

export default CommonRank
