import React from "react"
import { Affix } from "antd"
import Header from "@/components/Header"
import { useHistory, Route, Switch } from "react-router-dom"
import CommonRank from "./Common"
import Paper from "./Paper"
import Author from "./Author"

const Index: React.FC = () => {
  const history = useHistory()
  //搜索方法
  function onSearch(data: any) {
    let obj = data.value
    if (typeof data.value === "string") {
      obj = { [data.type]: data.value }
    }
    window.sessionStorage.setItem("gtx-miner-paperOption", JSON.stringify(obj))
    history.push("/search")
  }
  return (
    <div>
      <Affix offsetTop={0}>
        <Header onSearch={onSearch} />
      </Affix>
      <Switch>
        <Route exact path="/rank/org" render={() => <CommonRank type="org" />} />
        <Route exact path="/rank/journal" render={() => <CommonRank type="journal" />} />
        <Route exact path="/rank/paper" component={Paper} />
        <Route exact path="/rank/author" component={Author} />
      </Switch>
    </div>
  )
}

export default Index
