import React from "react"
import ReactEcharts from "echarts-for-react"

export interface ILineChartProps {
  data: number[]
  xAxis: number[]
  title?: string
  height?: string
}

const LineChart: React.FC<ILineChartProps> = ({
  data,
  xAxis,
  title = "papers",
  height = "100%"
}) => {
  const options = {
    color: ["#2A61E9"],
    grid: { top: 35, left: "3%", right: "4%", bottom: "3%", containLabel: true },
    legend: {
      data: [title]
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xAxis
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: true,
        name: title,
        areaStyle: {
          normal: {
            opacity: 0.5,
            color: "rgba(238,243,255)"
          }
        }
      }
    ],
    tooltip: {
      trigger: "axis"
    }
  }

  return <ReactEcharts option={options} style={{ height: height }} />
}

export default LineChart
