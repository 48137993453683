import React, { useEffect, useMemo, useState } from "react"
import Header from "@/components/Header"
import ResultItem from "@/components/ResultItem"
import SearchSort, { typeMapping as sortMapping } from "@/components/SearchSort"
import { getSearchList, ISearchListParams, downloadPapersPost } from "@/services"
import { Spin, Pagination, Affix } from "antd"
import { ISearchBoxValue } from "@/components/SearchBox"
import { SearchType } from "@/utils/common"
import { downloadFile } from "@/utils/utils"
import { baiduAnalysis } from "@/utils/utils"
interface IPageParams {
  page_no: number
  page_size: number | string
  sort_asc: string
  sorting_options: string
}

interface ISearchParams {
  type: SearchType
  value: string | Record<string, string> //值
}

const Search: React.FC = () => {
  const [initValue, setInitValue] = useState<ISearchBoxValue>({})
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageTotal, setPageTotal] = useState<number>(1)
  const [searchParams, setSearchParams] = useState<ISearchParams>({
    type: "author",
    value: ""
  })

  const [pageParams, setPageParams] = useState<IPageParams>({
    page_no: 1,
    page_size: 20,
    sort_asc: "desc",
    sorting_options: "Relevance"
  })

  const [citationData, setCitationData] = useState<{ showCheck: boolean; checkList: any[] }>({
    showCheck: false,
    checkList: []
  })

  const searchValues = useMemo(() => {
    if (typeof searchParams.value === "string") {
      return searchParams.value
    }
    const obj = searchParams.value as Record<string, string>
    return Object.keys(obj).reduce((prev: string, current: string) => {
      const value = obj[current]
      if (!value) {
        return prev
      }
      return prev ? `${prev},${current}:${value}` : `${current}:${value}`
    }, "")
  }, [searchParams.value])

  useEffect(() => {
    baiduAnalysis()

    const str = window.sessionStorage.getItem("gtx-miner-paperOption")
    const params = str ? JSON.parse(str) : null
    if (params) {
      //区分是普通搜索还是高级搜索
      const keys = Object.keys(params)
      let type: any = "advanced",
        value = params
      if (keys.length === 1) {
        //普通搜索
        type = keys[0]
        value = params[keys[0]]
        setInitValue({
          type
        })
      }

      const defaultSort: string = (sortMapping[type] || sortMapping.other)[0]
      const data: ISearchListParams = {
        type,
        value,
        data: {
          page_no: 1,
          page_size: 20,
          sort_asc: "desc",
          sorting_options: defaultSort
        }
      }
      requestData(data)
    }
  }, [])

  //搜索方法
  function onSearch(obj: any) {
    const defaultSort: string = (sortMapping[obj.type] || sortMapping.other)[0]
    const data: ISearchListParams = {
      ...obj,
      data: {
        page_no: 1,
        page_size: 20,
        sort_asc: "desc",
        sorting_options: defaultSort
      }
    }
    requestData(data)
  }

  //分页组件页码切换方法
  function onPageChange(page: number | string) {
    const data: ISearchListParams = {
      ...searchParams,
      data: {
        ...pageParams,
        page_no: page
      }
    }
    requestData(data)
  }

  //排序切换事件回调
  function onSortChange(val: string) {
    const data: ISearchListParams = {
      ...searchParams,
      data: {
        ...pageParams,
        page_no: 1,
        sorting_options: val
      }
    }
    requestData(data)
  }

  //请求数据
  function requestData(data: ISearchListParams) {
    setLoading(true)
    const ajaxModel = {
      ...data,
      data: {
        ...data.data,
        sorting_options: data.data.sorting_options.toLowerCase()
      }
    }
    getSearchList(ajaxModel)
      .then((res) => {
        //类型发生变化，需重置CitationData数据
        if (data.type !== searchParams.type) {
          setCitationData({
            showCheck: false,
            checkList: []
          })
        }
        setSearchParams({
          type: data.type,
          value: data.value
        })
        setList(res.data)
        setPageTotal(res.total)
        setPageParams(data.data)

        //搜索参数保存在sessionStorage中
        const params = data.type === "advanced" ? data.value : { [data.type]: data.value }
        window.sessionStorage.setItem("gtx-miner-paperOption", JSON.stringify(params))
        window.scrollTo({
          top: 0
        })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function GenerateCitationComponent() {
    if (["author", "org", "chemical"].includes(searchParams.type)) return <></>

    return citationData.checkList.length ? (
      <button className="btn-generator-citation btn-download" onClick={onDownload}>
        download
      </button>
    ) : (
      <button className="btn-generator-citation" onClick={onGenerateCitation}>
        generate citation text
      </button>
    )
  }

  function onGenerateCitation() {
    setCitationData({ ...citationData, showCheck: !citationData.showCheck })
  }

  function onCheckChange(result: any[]) {
    setCitationData({ ...citationData, checkList: result })
  }

  function onDownload() {
    downloadPapersPost(citationData.checkList).then((res) => {
      const name = res.headers["content-disposition"].split("=")[1]
      downloadFile(res.data, name.slice(1, -1) || "pmid_reference.txt")
      setCitationData({
        showCheck: false,
        checkList: []
      })
    })
  }

  return (
    <div>
      <Affix offsetTop={0}>
        <Header onSearch={onSearch} initValue={initValue} />
      </Affix>
      <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
        <div className="content-container" style={{ minHeight: 300 }}>
          <Affix offsetTop={90}>
            <div style={{ backgroundColor: "#fff" }}>
              <div className="search-content-wrapper">
                Search Results for <span>"{searchValues}"</span> :
              </div>
              {searchParams.type !== "chemical" && (
                <SearchSort
                  value={pageParams.sorting_options}
                  type={searchParams.type}
                  onChange={onSortChange}
                />
              )}
              <GenerateCitationComponent />
            </div>
          </Affix>
          {list.length !== 0 && (
            <>
              <div>
                {list.map((item, index) => (
                  <ResultItem
                    key={index}
                    type={searchParams.type}
                    data={item}
                    {...citationData}
                    onCheckChange={onCheckChange}
                  />
                ))}
              </div>
              <div className="search-page-wrapper">
                <Pagination
                  current={pageParams.page_no}
                  total={pageTotal}
                  pageSize={20}
                  onChange={onPageChange}
                  showSizeChanger={false}
                />
              </div>
            </>
          )}
        </div>
      </Spin>
    </div>
  )
}

export default Search
