import React, { useEffect, useState } from "react"
import SearchSort from "@/components/SearchSort"
import { getAuthorByOrg } from "@/services"
import { AuthorItem } from "@/components/ResultItem"
import { Pagination } from "antd"

const features = ["h-index", "g-index", "citation", "paper_num"]

interface IAuthorTabContentProps {
  orgId: string
  setPageLoading: (status: boolean) => void
}

const AuthorTabContent: React.FC<IAuthorTabContentProps> = ({ orgId, setPageLoading }) => {
  const [list, setList] = useState<any[]>([])
  const [pageTotal, setPageTotal] = useState<number>(0)
  const [pageParams, setPageParams] = useState<any>({
    pageno: 1,
    pagesize: 20,
    sorting_options: "h-index"
  })

  useEffect(() => {
    requestData(pageParams)
  }, [])

  function onSortChange(value: string) {
    requestData(Object.assign({}, pageParams, { sorting_options: value, pageno: 1 }))
  }

  function onPageChange(page: number) {
    requestData(Object.assign({}, pageParams, { pageno: page }))
  }

  function requestData(data: any) {
    setPageLoading(true)
    getAuthorByOrg(orgId, data)
      .then((res) => {
        setList(res.data)
        setPageParams(data)
        setPageTotal(res.total)
        document.querySelector(".detail-author-content")?.scrollTo({
          top: 0
        })
      })
      .finally(() => {
        setPageLoading(false)
      })
  }
  return (
    <div className="detail-author-wrapper">
      <SearchSort options={features} value={pageParams.sorting_options} onChange={onSortChange} />
      <div className="detail-author-content">
        <div>
          {list.map((item, index) => (
            <AuthorItem key={index} data={item} />
          ))}
        </div>
        <div className="search-page-wrapper">
          <Pagination
            current={pageParams.pageno}
            total={pageTotal}
            pageSize={20}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  )
}

export default AuthorTabContent
