import React, { useEffect, useState } from "react"
import { Pagination } from "antd"
import { OtherItem } from "@/components/ResultItem"
import { getPapersByAuthor, getPapersByOrg } from "@/services"

interface IPapersContent {
  authorId?: string
  orgId?: string
  years: Record<string | number, number>
  initData: any
  onFinishChangeYear?: (year: string | number) => void //年份切换完成时的回调函数，用于通知submit information提交修改年份参数
  setPageLoading: (loading: boolean) => void //设置页面加载状态
}
const PapersContent: React.FC<IPapersContent> = ({
  authorId,
  orgId,
  years,
  initData,
  onFinishChangeYear,
  setPageLoading
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [pageTotal, setPageTotal] = useState<number>(0)
  const [checkedYear, setCheckedYear] = useState<string | number>("ALL")
  const [pageParams, setPageParams] = useState<any>({
    pageno: 1,
    pagesize: 20
  })

  const yearsArray = Object.keys(years).reverse()

  useEffect(() => {
    setPageTotal(years[checkedYear])
  }, [years, checkedYear])

  useEffect(() => {
    setList(initData.data)
  }, [initData])

  useEffect(() => {
    //作者发生变化，重置数据
    setCheckedYear("ALL")
    setOpen(false)
    setPageParams({
      pageno: 1,
      pagesize: 20
    })
    document.querySelector(".detail-papers-content")?.scrollTo({
      top: 0
    })
  }, [authorId, orgId])

  //分页组件页码切换方法
  function onPageChange(page: number) {
    requestData(checkedYear, { ...pageParams, pageno: page })
  }

  function onChangeYear(year: string | number) {
    requestData(year, { ...pageParams, pageno: 1 })
  }

  function requestData(year: any, data: any) {
    const requestFunction = authorId ? getPapersByAuthor : getPapersByOrg
    const id = authorId || orgId
    if (!id) return
    setPageLoading(true)
    requestFunction(id, year.toLowerCase(), data)
      .then((res) => {
        setCheckedYear(year)
        setList(res.data)
        setPageParams(data)
        document.querySelector(".detail-papers-content")?.scrollTo({
          top: 0
        })
        if (year !== checkedYear && onFinishChangeYear) {
          onFinishChangeYear(year)
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
  }
  return (
    <div className="detail-papers-wrapper">
      <div className="detail-papers-years">
        <ul>
          {(open ? yearsArray : yearsArray.slice(0, 17)).map((item: any, index: number) => (
            <li key={index} className={item === checkedYear ? "active" : ""}>
              <span onClick={() => onChangeYear(item)}>{item}</span>
            </li>
          ))}
        </ul>
        {yearsArray.length > 17 && (
          <div className={open ? "open" : ""}>
            <img src="/assets/images/yearDropdown.svg" alt="" onClick={() => setOpen(!open)} />
          </div>
        )}
      </div>
      <div className="detail-papers-content">
        <div>
          {list.map((item, index) => (
            <OtherItem key={index} data={item} />
          ))}
        </div>
        <div className="search-page-wrapper">
          <Pagination
            current={pageParams.pageno}
            total={pageTotal}
            pageSize={20}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  )
}

export default PapersContent
