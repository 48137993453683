import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from "axios"
// import { createHashHistory } from "history"
import { message } from "antd"

const isPrd: boolean = process.env.NODE_ENV === "production"
const baseURL: string = isPrd ? "/api" : "/api"

const service: AxiosInstance = axios.create({
  baseURL
  // timeout: 10000
})

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const { headers } = config

    headers["Content-Type"] = headers["Content-Type"] || "application/json; charset=utf-8"

    return config
  },
  (error: AxiosError) => {
    return error
  }
)

service.interceptors.response.use(
  (response: AxiosResponse) => {
    // console.log(response)
    //通过responseType字段,判断是否是文件流
    if (response.config.responseType === "arraybuffer") {
      return response
    }

    // const history = createHashHistory()
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(response.data)
  },
  (error: AxiosError) => {
    message.error("服务器异常，请联系管理员！")
    return Promise.reject(error.response)
  }
)

export default service
