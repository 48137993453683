import React from "react"
import { Link } from "react-router-dom"
import SearchBox from "../SearchBox"
import BatchSearch from "../BatchSearch"
import { ISearchBoxValue } from "@/components/SearchBox"
import "./index.scss"

interface IHeaderProps {
  onSearch: (obj: any) => void
  initValue?: ISearchBoxValue
}

const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <div className="header-container">
      <Link to="/" className="header-logo">
        <img src="/assets/images/logo.svg" />
        <span>GTX.Miner</span>
      </Link>
      <SearchBox
        className="header-search-box"
        onSearch={props.onSearch}
        initValue={props.initValue}
      />
      <div className="header-right">
        <BatchSearch />
        <span>Contact: miner@gtxlab.com</span>
      </div>
    </div>
  )
}

export default Header
