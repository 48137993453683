/*
 * @Author: xiongyahui
 * @Date: 2021-12-17 11:33:14
 * @Description:
 */
import React, { useEffect, useState } from "react"
import MeshClass from "@/components/MeshClass"
import { getPaperRanking } from "@/services"
import { Spin, Pagination } from "antd"
import { OtherItem } from "@/components/ResultItem"
import { baiduAnalysis } from "@/utils/utils"

const PaperRank: React.FC = () => {
  const [pageParams, setPageParams] = useState<{ page_no: number; page_size: number }>({
    page_no: 1,
    page_size: 20
  })
  const [mesh, setMesh] = useState<string>("C")
  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    requestData("C", pageParams)
    baiduAnalysis()
  }, [])

  function onMeshClick(meshVal: string) {
    requestData(meshVal, Object.assign({}, pageParams, { page_no: 1 }))
  }

  function requestData(meshVal: string, data: any) {
    setLoading(true)
    getPaperRanking(meshVal, data)
      .then((res) => {
        setPageParams(data)
        setMesh(meshVal)
        setList(res.data)
        setTotal(res.total)
        window.scrollTo({
          top: 0
        })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  //分页组件页码切换方法
  function onPageChange(page: number) {
    const data = {
      ...pageParams,
      page_no: page
    }
    requestData(mesh, data)
  }
  return (
    <Spin spinning={loading} size="large" wrapperClassName="search-spin-wrapper">
      <div className="content-container rank-content-container">
        <MeshClass checked={mesh} clickCallback={onMeshClick} />
        <div className="rank-content-wrapper">
          <div className="rank-paper-title">Top1000 Papers:</div>
          <div>
            {list.map((item, index) => (
              <OtherItem key={index} data={item} rank={item.paper_code} />
            ))}
          </div>
          <div className="search-page-wrapper">
            <Pagination
              current={pageParams.page_no}
              total={total}
              pageSize={20}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default PaperRank
