import { createStore, Store, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"

import { IUserState } from "./user/types"
import user from "./user/reducer"

export interface IRootState {
  user: IUserState
}

export const store: Store<IRootState> = createStore(
  combineReducers({
    user
  }),
  applyMiddleware(thunk)
)

store.subscribe(function () {
  // console.log('store has been updated, Lastest store state:', store.getState())
})
