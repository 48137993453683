/*
 * @Author: xiongyahui
 * @Date: 2021-12-01 16:31:01
 * @LastEditors: xiongyahui
 * @LastEditTime: 2022-07-07 15:10:39
 * @Description:
 */
import React, { useEffect } from "react"
import SearchBox from "@/components/SearchBox/index"
import BatchSearch from "@/components/BatchSearch/index"
import { useHistory } from "react-router-dom"
import { baiduAnalysis } from "@/utils/utils"

const Home: React.FC = () => {
  const history = useHistory()
  const rankingList = [
    { name: "Author Ranking", img: "authorRanking", url: "/rank/author" },
    { name: "Organization Ranking", img: "orgRanking", url: "/rank/org" },
    { name: "Paper Ranking", img: "papersRanking", url: "/rank/paper" },
    { name: "Journal Ranking", img: "journalRanking", url: "/rank/journal" }
  ]

  useEffect(() => {
    baiduAnalysis()
  }, [])

  function onSearch(data: any) {
    let obj = data.value

    if (typeof data.value === "string") {
      obj = { [data.type]: data.value }
    }

    window.sessionStorage.setItem("gtx-miner-paperOption", JSON.stringify(obj))
    history.push("/search")
  }
  return (
    <div className="home-container">
      <div className="home-header-container">
        <div className="logo-wrapper">
          <img src="/assets/images/logo.svg" />
          <span>GTX.Miner</span>
        </div>
        <SearchBox onSearch={onSearch} />
        <BatchSearch />
      </div>

      <div className="home-ranking-container">
        <div className="home-ranking-title">Academic Ranking</div>
        <ul className="home-ranking-list">
          {rankingList.map((item, index) => (
            <li key={index} onClick={() => history.push(item.url)}>
              <div className="home-ranking-img">
                <img src={`/assets/images/${item.img}.svg`} alt="" />
              </div>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="home-footer-wrapper">Contact: miner@gtxlab.com</div>
    </div>
  )
}

export default Home
